<template>
  <div>
    <!-- <div id="capture" style="padding: 10px; background: #f5da55">
      <h4 style="color: #000; ">Hello world!</h4>
    </div>
    <div @click="onClick">点击保存</div>
    <img src id="imgShow" /> -->

<div  class="code"  :data-clipboard-text="userInfo.account"  @click="copy">
        <div>{{userInfo.account}}</div>
        <div>(点击复制)</div>
      </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import Clipboard from 'clipboard'
export default {
  data(){
    return{
      userInfo:{
        account:"123123123123"
      }
    }
  },
  methods: {
    onClick() {
      html2canvas(document.querySelector("#capture")).then(canvas => {
        // document.body.appendChild(canvas);
        console.log(canvas, "123");
        console.log(canvas.toDataURL());
        var _imgSrc = canvas.toDataURL("image/png", 1);
        canvas.style.display = "none";
        // let base = canvas.toDataURL();
        var imgShow = document.getElementById("imgShow");
        imgShow.setAttribute("src", _imgSrc);
      });
    },


 copy () {
      var clipboard = new Clipboard('.code')
      clipboard.on('success', e => {
        console.log('复制成功', e)
        // Toast({
        //   message: '复制成功'
        // })
        // 释放内存
        clipboard.destroy()
      })
      // clipboard.on('error', e => {
      //   // 不支持复制
      //   // Toast({
      //   //   message: '手机权限不支持复制功能'
      //   // })
      //   console.log('该浏览器不支持自动复制')
      //   // 释放内存
      //   clipboard.destroy()
      // })
    },
  }
};
</script>
<style lang="scss" scoped>
#imgShow {
  width: 100%;
  height: 100%;
}
</style>